import * as React from "react"

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ProfileData = (props) => {
    var data = props.graphData ? props.graphData : props.graphData

    console.log(data);

    return (
        <div id="profile-div">
            <p><strong>First Name: </strong> {data.givenName ? data.givenName : data.given_name}</p>
            <p><strong>Last Name: </strong> {data.surname ? data.surname : data.family_name}</p>
            <p><strong>Email: </strong> {data.userPrincipalName ? data.userPrincipalName : data.emails[0]}</p>
            <p><strong>Id: </strong> {data.id ? data.id : data.oid}</p>
        </div>
    );
};
