import React, { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import Layout from "../components/layout";
import Seo from "../components/seo"
import { ProfileData } from "../components/profileData";
import { Loading } from "../components/loading";
import { ErrorComponent } from "../components/errorComponent";
import { callMsGraph } from "../utils/msGraphApiCall";
import { parseJwt } from "../utils/parseJwt";

// Material-ui imports
import Paper from '@mui/material/Paper';

const ProfileContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (account && !graphData && inProgress === InteractionStatus.None) {
            const request = {
                ...loginRequest,
                account: account
            };
            instance.acquireTokenSilent(request).then((response) => {
                window.localStorage.setItem("authClaims", JSON.stringify(response.account.idTokenClaims));
                var parsedToken = parseJwt(response.accessToken)
                parsedToken.idp_access_token ? 
                    callMsGraph(parsedToken.idp_access_token).then(response => setGraphData(response)) : 
                    setGraphData(parsedToken)
            }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request);
                }
            });
        }
    }, [account, inProgress, instance, graphData]);
  
    return (
        <Paper>
            { graphData ? <ProfileData graphData={graphData} /> : null }
        </Paper>
    );
};

const Profile = () => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <Layout>
            <Seo title="Profile" />
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={Loading}
            >
                <ProfileContent />
            </MsalAuthenticationTemplate>
        </Layout>
      )
};

export default Profile;
